import { waitForFinalEvent } from "./betterAwaits.js";

(function($) {

	if( $('.inspirations').length > 0 ) {
		var inspirationsCarousel = {
			init: function(){
				$('.inspirations').each(function(){
                    const slider      = $(this).find('.inspirations__swiper')
                    const prevArrow   = $(this).find('.inspirations__arrow.prev');
                    const nextArrow   = $(this).find('.inspirations__arrow.next');
 
                    const swiper = new Swiper(slider,{
                        speed: 2000,
                        loop: true,
                        // autoplay: {
                        //   delay: 3000,
                        // },
                        centeredSlides: true,
                        slidesPerView: 0.9,
                        spaceBetween: 20,         
                        breakpoints: {
                            430: {
                              slidesPerView: 1.6,
                              spaceBetween: 20
                            },
                            768: {
                              slidesPerView: 2.6,
                              spaceBetween: 20
                            },

                            1024: {
                              slidesPerView: 2.6,
                              spaceBetween: 32
                            },

                            // 1690: {
                            //   slidesPerView: 5,
                            //   spaceBetween: 32
                            // }        
                        },
                        navigation: {
                            nextEl: '.inspirations__arrow.next',
                            prevEl: '.inspirations__arrow.prev',
                        },

                    });
			 	});
				
			}
		};

		$(function() {
			inspirationsCarousel.init();
		});

        $(window).on("resize orientationchange", function () {
            waitForFinalEvent(function(){
                inspirationsCarousel.init();
            }, 500, 'inspirations-carousel');
        });	

	}
})(jQuery);