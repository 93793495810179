import { waitForFinalEvent } from "./betterAwaits.js";

(function($) {
	if ( $('.resources-search').length > 0 ) {

		let resultWrapper      = $('.resources-search').find('.resources-search__results-wrapper');
		let resetButtonWrapper = $('.resources-search').find('.resources-search__reset-wrapper');
		let sectionSiblings    = $('.resources-search').siblings('section');

		const ajaxSearch = {
			init: function(value){
				let keyword            = value;
				let result             = [];

				if( keyword !== '' ) {

					$.ajax({
						url: frontendajax.ajaxurl,
						type: 'POST',
						data: {
							action: 'search_resources',
							search_term: keyword
						},
						beforeSend: function(){
							resultWrapper.addClass('loading');
                        },						
						success: function(response) {							
							resultWrapper.html(response);
							resetButtonWrapper.show();
							sectionSiblings.hide();
							resultWrapper.removeClass('loading');
						}
					});

				} else {
					resultWrapper.html('');
				}
	
			}
		}


		// $('.resources-search input.resources-search__search-input').on('keyup', function(){
		// 	var searchTerm = $(this).val();
		// 	waitForFinalEvent(function(){
		// 		ajaxSearch.init( searchTerm );
		// 	}, 750, 'resources-search');				
		// });
		$('.resources-search .resources-search__search-button').on('click',function(event) {
			event.preventDefault();
			var searchTerm = $('.resources-search__search-input').val();
			waitForFinalEvent(function(){
				ajaxSearch.init( searchTerm );
			}, 750, 'resources-search-button');
		})

		$('.resources-search .resources-search__reset-button').on('click',function(event) {
			event.preventDefault();
			$('.resources-search__search-input').val('');
			resetButtonWrapper.hide();
			sectionSiblings.show();
			resultWrapper.html('');
		})

	}


})(jQuery);