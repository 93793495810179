import { waitForFinalEvent } from "./betterAwaits.js";

(function($) {
    var productSummary = {
        init: function(){
            if ( $('.product-summary__description').length > 0 ) {            
                var summary      = $('.product-summary__description');
                var fontSize     = summary.find('p').css('font-size');
                var lineHeightPx = summary.find('p').css('line-height');
                var lineHeight   = Math.floor( parseInt(lineHeightPx.replace('px','') ));
                var showButton   = $('.product-summary__description-read-more');
                var actualHeight = $('.product-summary__description').outerHeight();
                var showHeight   = 3*lineHeight;
                var firstElementHeight = summary.children(":first").outerHeight();

                // console.log(actualHeight);
                // console.log(showHeight);
                // console.log(firstElementHeight);

                if ( firstElementHeight <= showHeight) {
                    //hide summary after 3rd line
                    summary.css('height',firstElementHeight);

                    showButton.on('click',function(e){
                        e.preventDefault();
                        summary.toggleClass('showall');
                        showButton.text( ( showButton.text() == "show more") ? "show less" : "show more" );
                    });

                    if ( actualHeight <= showHeight )  {
                        showButton.hide();
                        summary.addClass('loaded');
                    } else {
                        showButton.addClass('loaded');
                        summary.addClass('loaded');
                    }                    

                } else if ( actualHeight > showHeight ) {

                    //hide summary after 3rd line
                    summary.css('height',showHeight);

                    showButton.on('click',function(e){
                        e.preventDefault();
                        summary.toggleClass('showall');
                        showButton.text( ( showButton.text() == "show more") ? "show less" : "show more" );
                    });

                    showButton.addClass('loaded');
                    summary.addClass('loaded');



                }
                
            }
        }
    };

    $(function() {
        waitForFinalEvent(function(){
            productSummary.init();
        }, 500, 'product-summary-init');
    });

    $(window).on("resize orientationchange", function () {
        waitForFinalEvent(function(){
            productSummary.init();
        }, 500, 'product-summary');
    });

})(jQuery);