import { waitForFinalEvent } from "./betterAwaits.js";
import Swiper from 'swiper/bundle';
import 'swiper/scss';

(function($) {

	if( $('.popular-products').length > 0 ) {
		var popularProductsCarousel = {
			init: function(){
				$('.popular-products').each(function(){
                  
                    const swiper = new Swiper('.popular-products__swiper',{
                        speed: 500,
                        slidesPerView: 1.64,
                        spaceBetween: 16,         
                        breakpoints: {   
                            430: {
                              slidesPerView: 2.5,
                              spaceBetween: 16
                            },
                            768: {
                              slidesPerView: 3.25,
                              spaceBetween: 16
                            },

                            1024: {
                              slidesPerView: 4.25,
                              spaceBetween: 22
                            },

                            1280: {
                              slidesPerView: 4.5,
                              spaceBetween: 22
                            }        
                        },
                        navigation: {
                            nextEl: '.popular-products__arrow.next',
                            prevEl: '.popular-products__arrow.prev',
                        },


                        scrollbar: {
                            el: '.popular-products__dots-wrapper',
                            dragSize: 200,
                            draggable: true,
                            snapOnRelease: true
                        },
                    });
                });
				
			}
		};

		$(function() {
			popularProductsCarousel.init();
		});

	}
})(jQuery);