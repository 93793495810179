import { waitForFinalEvent } from "./betterAwaits.js";

(function($) {

	//preparations 
	var getTopHeight = {
		init: function(){
			// Get the height of '.notification-bar'
			var notificationBarHeight = $('.notification-bar').outerHeight() || 0;

		    // Get the height of '.site-header'
			var siteHeaderHeight = $('.site-header').outerHeight() || 0;

    		// Calculate the total height
			var totalHeight = notificationBarHeight + siteHeaderHeight;

			// var windowHeight = $(window).height();

			// var menuHeight = windowHeight - totalHeight;

			var dynamicHeight = 'calc(100dvh - ' + totalHeight + 'px)';

		    // Set the top property of '.mobile-menu__outer' and '.mobile-menu__inner'
			$('.mobile-menu__outer, .mobile-menu__inner').css('top', totalHeight + 'px');
			$('.mobile-menu__outer, .mobile-menu__inner').css('height', dynamicHeight);
			$('.mobile-menu').css('height', dynamicHeight);
		}
	};

	getTopHeight.init();

	$(window).on("resize orientationchange", function () {
		waitForFinalEvent(function(){
			getTopHeight.init();
		}, 500, 'menuTop');
	});	


	// handle mobile menu icon actions
	$('.menu-toggle').on('click', function (e) {
		e.preventDefault();
		$(this).toggleClass('open');
		$('.mobile-menu__wrapper').toggleClass('active');
		$('body').toggleClass('menu-active');
	});


	// handle close button action
	$('.mobile-menu__outer').on('click', function () {
		$('.menu-toggle').removeClass('open');
		$('.mobile-menu__wrapper').removeClass('active');
		$('body').removeClass('menu-active');
		// $('.mobile-menu').removeClass('child-active');
		// $('ul.sub-menu').removeClass('child-active');
		// $('.mobile-menu').find('.sub-menu').removeClass('show');		
	});


	// main scripts to handle the pseudo menu and actions
	$('#mobile-menu')
	.find('.menu-item-has-children')
	.each(function () {

		//copy the parent menu to the bottom sub-menu to make a view all link.
		$(this).find('.sub-menu').each(function(){
			if ( !$(this).hasClass('viewall-ok') ) {		    	
				let parentMenuOriginal = $(this).prev().clone().html('View All');
				let parentMenu = $("<div />").append( parentMenuOriginal ).html();				
				$(this).append('<li class="mobile-menu__view-all">'+parentMenu+'</li>');
				$(this).addClass('viewall-ok');
			}
		});


	    //create a back button on the sub-menu
		$(this).find('.sub-menu').each(function(){
			if ( !$(this).hasClass('back-ok') ) {
				$(this).prepend('<li class="mobile-menu__back"><a href="#">back</a></li>');
				$(this).addClass('back-ok');
			}
		});

    	//action on the menu item click
		$(this)
		.children('a')
		.on('click', function (e) {
			e.preventDefault();
			$(this).parents('ul').animate({ scrollTop: 0 }, 'fast', function() {
				$(this).addClass('child-active');
            });
			// $(this).parents('ul').addClass('child-active');
			$(this).next('.sub-menu').addClass('show');
		});
	});

	// handle back button actions
	$(document).on('click', '.mobile-menu__back', function () {
		$(this).parent('.sub-menu').removeClass('show');
		$(this).parents('ul.child-active').removeClass('child-active');
	});

})(jQuery);
