import { waitForFinalEvent } from "./betterAwaits.js";

(function($) {

	if( $('.news-box').length > 0 ) {
		var newsCarousel = {
			init: function(){
				$('.content-card-slider').each(function(){
                    const slider = $(this).find('.content-card__wrapper').not('.slick-initialized');
                    // const arrowWrapper = $(this).find('.projects-carousel__arrow-wrapper');
                    const settings = {
                        infinite: true,
                        dots: false,
                        arrows: false,
                        autoplay: true,
                        autoplaySpeed: 7000,
                        slidesToShow: 1,
                        slidesToScroll: 1,                        
                        mobileFirst: true,
                        responsive: [
                            {
                                breakpoint: 430,
                                settings: {
                                    slidesToShow: 2,
                                }
                            },
                            {
                                breakpoint: 1023,
                                settings: "unslick"
                            }

                        ]
                    };

                    const sl =  slider.slick(settings);

					$(window).on("resize orientationchange", function () {
						waitForFinalEvent(function() {
						  if( $(window).width() < 1080 ) {
							newsCarousel.init();
						  }	
						}, 500, 'news-carousel');
					});
			 	});
				
			}
		};

		newsCarousel.init();


        $('.news-box').each(function(){
            const contentWrapper = $(this).find('#news-box-content-wrapper');

            $(this).find('.news-box__category:not(.all)').each(function(){
                $(this).on('click',function(e){
                    e.preventDefault();
                    $('.news-box__category').removeClass('active');
                    $(this).addClass('active');
                    const target = $(this).data('term-id');
                    $.ajax({
                        url: frontendajax.ajaxurl,
                        type: 'post',
                        data: {
                            action  : 'ajax_get_posts',
                            term_id : target
                        },
                        beforeSend: function(){
                            contentWrapper.addClass('loading');
                            contentWrapper.removeClass('.slick-initialized');
                            contentWrapper.removeClass('.slick-slider');
                            contentWrapper.removeClass('.slick-dotted');                            
                        },
                        success: function( result ) {                            
                            contentWrapper.slick('unslick');
                            contentWrapper.html(result);
                            newsCarousel.init();
                            contentWrapper.removeClass('loading');                            
                        }
                    })                    
                });
            })

            $(this).find('.news-box__category.all').each(function(){
                $(this).on('click',function(e){
                    e.preventDefault();
                    $('.news-box__category').removeClass('active');
                    $(this).addClass('active');
                    var args = $(this).data('args');
                    $.ajax({
                        url: frontendajax.ajaxurl,
                        type: 'post',
                        data: {
                            action  : 'ajax_get_posts',
                            args    : args
                        },
                        beforeSend: function(){
                            contentWrapper.addClass('loading');
                            contentWrapper.removeClass('.slick-initialized');
                            contentWrapper.removeClass('.slick-slider');
                            contentWrapper.removeClass('.slick-dotted');                            
                        },
                        success: function( result ) {                            
                            contentWrapper.slick('unslick');
                            contentWrapper.html(result);
                            newsCarousel.init();
                            contentWrapper.removeClass('loading');                            
                        }
                    })                    
                });
            })

        });

	}
})(jQuery);