import { waitForFinalEvent } from "./betterAwaits.js";

(function($) {
	if ( $('.post-search').length > 0 ) {
		let resultWrapper  = $('.post-search').find('.post-search__results-wrapper');
		let resetButton    = $('.post-search').find('.post-search__reset-button');
		let submitButton   = $('.post-search').find('.post-search__search-button');

		// First Word
		let firstWordInput        = $('.post-search').find('#search-first-word');
		let firstAutocompleteList = $('.post-search').find('#first-word-autocomplete-list');

		function showAutocompleteFirst(list) {
            firstAutocompleteList.empty();
            list.forEach(word => {
                const item = $('<div class="autocomplete-item"></div>').text(word);
                item.on('click', function () {
                    firstWordInput.val(word);
                    firstAutocompleteList.empty();
                    firstAutocompleteList.removeClass('show');
                });
                firstAutocompleteList.append(item);
                firstAutocompleteList.addClass('show');
            });
        }

		firstWordInput.on('focus', function () {
            showAutocompleteFirst(firstWordLists);
        });

        firstWordInput.on('input', function () {
            const firstFilteredWords = firstWordLists.filter(word => word.toLowerCase().includes(firstWordInput.val().toLowerCase()));
            showAutocompleteFirst(firstFilteredWords);
        });

        $(document).on('click', function (e) {
            if (!$(e.target).closest('#search-first-word').length) {
                firstAutocompleteList.empty();
                firstAutocompleteList.removeClass('show');
            }
        });


		// Second Word
		let secondWordInput        = $('.post-search').find('#search-second-word');
		let secondAutocompleteList = $('.post-search').find('#second-word-autocomplete-list');

		function showAutocompleteSecond(list) {
            secondAutocompleteList.empty();
            list.forEach(word => {
                const item = $('<div class="autocomplete-item"></div>').text(word);
                item.on('click', function () {
                    secondWordInput.val(word);
                    secondAutocompleteList.empty();
                    secondAutocompleteList.removeClass('show');
                });
                secondAutocompleteList.append(item);
                secondAutocompleteList.addClass('show');
            });
        }

		secondWordInput.on('focus', function () {
            showAutocompleteSecond(secondWordLists);
        });

        secondWordInput.on('input', function () {
            const secondFilteredWords = secondWordLists.filter(word => word.toLowerCase().includes(secondWordInput.val().toLowerCase()));
            showAutocompleteSecond(secondFilteredWords);
        });

        $(document).on('click', function (e) {
            if (!$(e.target).closest('#search-second-word').length) {
                secondAutocompleteList.empty();
                secondAutocompleteList.removeClass('show');
            }
        });

		const ajaxSearch = {
			init: function(value){
				let keyword = value;
				let result = [];


				if( keyword !== '' ) {

					$.ajax({
						url: frontendajax.ajaxurl,
						type: 'POST',
						data: {
							action: 'search_resources',
							post_type: 'post',
							search_term: keyword
						},
						beforeSend: function(){
							resultWrapper.addClass('loading');
                        },						
						success: function(response) {							
							resultWrapper.html(response);
							resetButton.addClass('show');
							resultWrapper.removeClass('loading');
						}
					});

				} else {
					resultWrapper.html('');
				}
	
			}
		}


		submitButton.on('click',function(event) {
			event.preventDefault();
			
			let firstWord  = $('.post-search #search-first-word').val();
			let secondWord = $('.post-search #search-second-word').val();

			var searchTerm = firstWord + ' ' + secondWord;

			waitForFinalEvent(function(){
				ajaxSearch.init( searchTerm );
			}, 750, 'post-search-button');
		})

		resetButton.on('click', function(e) {
			e.preventDefault();
			firstWordInput.val('');
			secondWordInput.val('');
			resultWrapper.html('');
			$(this).removeClass('show');
		});

	}


})(jQuery);