import { waitForFinalEvent } from "./betterAwaits.js";
import Swiper from 'swiper/bundle';
import 'swiper/scss';

(function($) {

	if( $('.category-boxes').not('.no-carousel').length > 0 ) {
		var catBoxesCarousel = {
			init: function(){
				$('.category-boxes').not('.no-carousel').each(function(index, element){
                    $(this).find('.category-boxes__swiper').addClass('category-boxes__swiper-'+index);
                    $(this).find('.category-boxes__dots-wrapper').addClass('category-boxes__dots-wrapper-'+index);
                    $(this).find('.category-boxes__arrow.prev').addClass('category-boxes__arrow-'+index);
                    $(this).find('.category-boxes__arrow.next').addClass('category-boxes__arrow-'+index);

                    const swiper = new Swiper('.category-boxes__swiper-'+index,{
                        speed: 500,
                        // loop: true,
                        // autoplay: {
                        //   delay: 3000,
                        // },
                        slidesPerView: 1.3,
                        spaceBetween: 20,         
                        breakpoints: {
                            430: {
                              slidesPerView: 2.25,
                              spaceBetween: 20
                            },

                            768: {
                              slidesPerView: 2.5,
                              spaceBetween: 20
                            },

                            1024: {
                              slidesPerView: 3.25,
                              spaceBetween: 20
                            },

                            1440: {
                              slidesPerView: 3.5,
                              spaceBetween: 32
                            }        
                        },
                        navigation: {
                            nextEl: '.category-boxes__arrow-'+index+'.next',
                            prevEl: '.category-boxes__arrow-'+index+'.prev',
                        },


                        scrollbar: {
                            el: '.category-boxes__dots-wrapper-'+index,
                            dragSize: 200,
                            draggable: true,
                            snapOnRelease: true
                        },
                    });
			 	});
				
			}
		};

        catBoxesCarousel.init();

	}
})(jQuery);