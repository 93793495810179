import { waitForFinalEvent } from "./betterAwaits.js";
import Swiper from 'swiper/bundle';
import 'swiper/scss';

(function($) {
    //tab function 
    if ( $('.product-carousel').length > 0 ){
        $('.product-carousel').each(function(){
            const tabTitle   = $(this).find('.product-carousel__tab-title');
            const tabContent = $(this).find('.product-carousel__content');
            tabTitle.each(function(){
                $(this).on('click', function(e){
                    e.preventDefault();

                    tabTitle.removeClass('active');
                    $(this).addClass('active');

                    const tabTarget = $(this).attr('href');
                    tabContent.removeClass('active');
                    $(tabTarget).addClass('active');
                });
            });
        });
    }


    // carousel function
	if( $('.product-carousel__content').not('.no-carousel').length > 0 ) {
		var productCarousel = {
			init: function(){
				$('.product-carousel__content').not('.no-carousel').each(function(index, element){
                  
                    const swiper = new Swiper('.product-carousel__swiper-'+index,{
                        speed: 500,
                        // loop: true,
                        // autoplay: {
                        //   delay: 3000,
                        // },
                        slidesPerView: 1.64,
                        spaceBetween: 16,         
                        breakpoints: {                                                     
                            430: {
                              slidesPerView: 2.5,
                              spaceBetween: 16
                            },

                            768: {
                              slidesPerView: 3.25,
                              spaceBetween: 16
                            },

                            1024: {
                              slidesPerView: 4.25,
                              spaceBetween: 22
                            },

                            1280: {
                              slidesPerView: 4.5,
                              spaceBetween: 22
                            }        
                        },
                        navigation: {
                            nextEl: '.product-carousel__arrow-'+index+'.next',
                            prevEl: '.product-carousel__arrow-'+index+'.prev',
                        },


                        scrollbar: {
                            el: '.product-carousel__dots-wrapper-'+index,
                            dragSize: 200,
                            draggable: true,
                            snapOnRelease: true
                        },
                    });
			 	});
				
			}
		};

		productCarousel.init();

	}
})(jQuery);