(function($) {

	$('.faqs-search input[name=search]').on('keyup', function() {
		let keyword = $(this).val().trim().toLowerCase();

		let result = [];

		if( keyword !== '' )
		{
			let i      = 0;
			let limit  = 10;
			$(faqs_search.faqs).each(function(index, value) {
				if(value.title.toLowerCase().indexOf(keyword) > -1 || value.content.toLowerCase().indexOf(keyword) > -1)
				{
					result.push(value);

					i++;

					if( i == limit) {
						return false;
					}
				}
			});
		}

		let html = '';

		$.each(result, function(index, value) {
			html += `<a class="faqs-search__result-item" href="#" data-id="${value.id}">${value.title}</a>`;
		});

		if (html.length > 0 ) {
			$(this).closest('div').find('.faqs-search__input').addClass('show');
			$(this).closest('div').find('.faqs-search__result').html(html).addClass('show');
		} else {
			let noResults = '<i class="nothing">Sorry, but nothing matched your search terms. Please try again with some different keywords.</i>';
			$(this).closest('div').find('.faqs-search__result').html(noResults).addClass('show');
		}

		if ( keyword == "" ) {
			$(this).closest('div').find('.faqs-search__result').removeClass('show');
		}
	});

	$('.faqs-search').on('click', '.faqs-search__result-item', function(e) {
		e.preventDefault();
		let id = $(this).attr('data-id');

		if($('.faqs__item[data-id="' + id + '"]').length) {
			$('.faqs__item').removeAttr( 'open' );

			if( $('.faqs__item[data-id="' + id + '"]:eq(0):not([open])') )  {
				$('.faqs__item[data-id="' + id + '"]:eq(0)').attr('open','open');
			}


			$([document.documentElement, document.body]).animate({
				scrollTop: ($('.faqs__item[data-id="' + id + '"]:eq(0)').offset().top - 30)
			}, 1000);

			$('.faqs-search').find('.faqs-search__input').removeClass('show');
			$('.faqs-search').find('.faqs-search__result').removeClass('show');
		}
	});


})(jQuery);