(function($) {

	if( $('.site-header').length > 0 ) {
		var searchButton = {
			init: function(){
				$('.site-header').each(function(){
					let openSearchButton  = $(this).find('.seach-icon');
					let closeSearchButton = $(this).find('.search-close');
					let searchWrapper     = $(this).find('.main-header__search-wrapper');

					openSearchButton.on('click', function(e){
						e.preventDefault();
						searchWrapper.addClass('active');
					});

					closeSearchButton.on('click', function(e){
						e.preventDefault();
						searchWrapper.removeClass('active');
					});
			 	});			
			}
		};

		searchButton.init();

	}
})(jQuery);