jQuery(document).ready(function($) {
    // Flag untuk mencegah redirect loop
    var isRedirecting = false;

    // Function to handle redirection
    function handleRedirection(value) {
        // Cek kondisi jika sedang redirect
        if (isRedirecting) {
            return;
        }

        // Cek dan redirect hanya jika dibutuhkan
        if (value === 'nz' && window.location.href !== localStorage.getItem('nz_link')) {
            isRedirecting = true; // Set flag menjadi true
            window.location.href = localStorage.getItem('nz_link'); // Redirect ke NZ
        } else if (value === 'au' && window.location.href !== localStorage.getItem('au_link')) {
            isRedirecting = true; // Set flag menjadi true
            window.location.href = localStorage.getItem('au_link'); // Redirect ke AU
        }
    }

    // Function to update dropdown and save to localStorage
    function updateDropdowns(value) {
        $('#site_1, #site_2').val(value);
        localStorage.setItem('selectedSite', value);
    }

    // Set initial state from localStorage
    var selectedSite = localStorage.getItem('selectedSite');
    if (selectedSite) {
        updateDropdowns(selectedSite);
    }

    // On change event to update both dropdowns and save to localStorage
    $('#site_1, #site_2').change(function() {
        var value = $(this).val();
        updateDropdowns(value);
        handleRedirection(value); // Panggil handleRedirection setelah update dropdown
    });
});
