import { waitForFinalEvent } from "./betterAwaits.js";
import Swiper from 'swiper/bundle';
import 'swiper/scss';

(function($) {

	if( $('.related-products').length > 0 ) {
		var relatedProductsCarousel = {
			init: function(){
                $('.related-products').each(function(){
                  
                    const swiper = new Swiper('.related-products__swiper',{
                        speed: 500,
                        slidesPerView: 1.64,
                        spaceBetween: 16,                        
                        breakpoints: {   
                            430: {
                              slidesPerView: 2.5,
                              spaceBetween: 16
                            },
                            768: {
                              slidesPerView: 3.25,
                              spaceBetween: 16
                            },

                            1024: {
                              slidesPerView: 4.25,
                              spaceBetween: 22
                            },

                            1280: {
                              slidesPerView: 4.5,
                              spaceBetween: 22
                            }        
                        },
                        navigation: {
                            nextEl: '.related-products__arrow.next',
                            prevEl: '.related-products__arrow.prev',
                        },


                        scrollbar: {
                            el: '.related-products__dots-wrapper',
                            dragSize: 200,
                            draggable: true,
                            snapOnRelease: true
                        },
                    });
                });
				
			}
		};

        var disableSlider = {
            init: function(){
                if ( window.innerWidth > 1023 ) {
                    if($(".related-products__swiper .swiper-slide").length < 5) {
                        $('.related-products__swiper').addClass( "disabled" );
                        $('.related-products__swiper .swiper-wrapper').addClass( "disabled" );
                        $('.related-products__arrows-wrapper').addClass( "disabled" );
                    }
                } else if ( window.innerWidth > 767 ) {
                    if($(".related-products__swiper .swiper-slide").length < 4) {
                        $('.related-products__swiper').addClass( "disabled" );
                        $('.related-products__swiper .swiper-wrapper').addClass( "disabled" );
                        $('.related-products__arrows-wrapper').addClass( "disabled" );
                    }
                }     
            },
            destroy: function () {
                $('.related-products__swiper').removeClass( "disabled" );
                $('.related-products__swiper .swiper-wrapper').removeClass( "disabled" );
                $('.related-products__arrows-wrapper').removeClass( "disabled" );
            }
        };

		$(function() {
			relatedProductsCarousel.init();
            disableSlider.init();
		});

        $(window).on("resize orientationchange", function () {
            waitForFinalEvent(function(){
                relatedProductsCarousel.init();
                disableSlider.destroy();
                disableSlider.init();
            }, 500, 'relProducts');
        }); 
	}
})(jQuery);