import { waitForFinalEvent } from "./betterAwaits.js";
import Swiper from 'swiper/bundle';
import 'swiper/scss';

(function($) {

	if( $('.our-brands').not('.no-carousel').length > 0 ) {
        const swiper = new Swiper('.our-brands__swiper',{
            speed: 4000,
            loop: true,
            allowTouchMove: false,
            autoplay: {
              delay: 0, 
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            slidesPerView: 1,
            spaceBetween: 20,            
            breakpoints: {        
                320: {
                  slidesPerView: 2
                },
                
                480: {
                  slidesPerView: 3
                },
                
                768: {
                  slidesPerView: 4
                },

                1280: {
                  slidesPerView: 5
                }        
            }    
        });
	}
    
})(jQuery);