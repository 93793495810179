(function($) {

	if( $('.woocommerce-product-gallery').length > 0 ) {

        let total_slide = $('.woocommerce-product-gallery__wrapper').find('.woocommerce-product-gallery__image').length;

        if (total_slide == 1 ) {

            $('.product-summary__image-arrow-wrapper').hide();
            
        } else {

            // $('.product-summary__image-counters').find('.total-slide').text( total_slide );

            
            // setTimeout(function() {

            //     // Function to find the index of the element with the class 'active'
            //     function findActiveIndex() {
            //         var activeIndex = $('.woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image.flex-active-slide').index();
            //         $('.product-summary__image-counters').find('.current-slide').text( activeIndex );
            //     }

            //     // Initial call to find the index
            //     findActiveIndex();

            //     // Create a new MutationObserver
            //     var observer = new MutationObserver(function(mutations) {
            //         // Call the function to find the index whenever a mutation occurs
            //         findActiveIndex();
            //     });

            //     // Configure the observer to watch for changes in the parent container
            //     var config = { childList: true, subtree: true };
            //     observer.observe($('.woocommerce-product-gallery__wrapper')[0], config);

            // }, 5000);


            $('.product-summary__image-arrow.prev').on('click', function(e){
                e.preventDefault();

                $('.flex-direction-nav').find('.flex-prev')[0].click();
            });

            $('.product-summary__image-arrow.next').on('click', function(e){
                e.preventDefault();

                $('.flex-direction-nav').find('.flex-next')[0].click();
            });

        }

        $('.product-summary__zoom').on('click', function(e){
            e.preventDefault();

            $('.woocommerce-product-gallery__trigger')[0].click();
        });

	}

})(jQuery);