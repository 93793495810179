(function($) {

	if ( $('.show-more-products'). length > 0 ) {
		$('.show-more-products').each(function() {
			$(this).on('click', function(e){
				e.preventDefault();
				$(this).next('.product-section').fadeIn();
				$(this).next('.product-section').removeClass('hidden');
				$(this).remove();
			});
		});
	}


})(jQuery);