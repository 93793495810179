(function ($) {
  if ($('.internal-banner-slider').length > 0) {
    var bannerSlider = {
      init: function(){
        $('.internal-banner-slider').each(function(){
            const slider = $(this).not('.slick-initialized');                    
            const settings = {
                infinite: true,
                // dots: true,
                // arrows: true,
                autoplay: true,
                autoplaySpeed: 5000,
                slidesToShow: 1,
                slidesToScroll: 1,
            };

            slider.slick(settings);
        });
        
      }
    };

    $(function() {
      bannerSlider.init();
    });
  }

})(jQuery);
